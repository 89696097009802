import React from "react";

const LoadingWheelComponent = () => {
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  };

  const circleStyle = {
    border: "4px solid rgba(0, 0, 0, 0.1)",
    borderLeft: "4px solid #3498db",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
  };

  const keyframes = `@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }`;

  return (
    <div style={containerStyle}>
      <style>{keyframes}</style>
      <div style={circleStyle}></div>
    </div>
  );
};

export default LoadingWheelComponent;
