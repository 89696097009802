import React, { useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import PropTypes from "prop-types";
import style from "../../MemberListTable.module.css";
import Typography from "../../../../../../../shared/typography/Typography";
import {
  APP_TENANT,
} from "../../../../../../../../utils/constants";
import ButtonComponent from "../../../../../../../shared/button/ButtonComponent";
import PlenaryMembersModalComponent from "./components/plenaryMembersModalComponent/PlenaryMembersModalComponent";
import { BUTTON } from "../../../../../../../shared/button/buttonConstants";
import { Redirect } from "react-router-dom";

const RowComponent = ({ row, columns, onReloadData }) => {
  const [id, setId] = useState(null);
  const handleRedirect = (newId) => {
    setId(newId);
  };

  if (id) {
    return <Redirect to={`/${APP_TENANT}/miembros/edit/${id}`} />;
  }

  return (
    <TableRow className={style.row}>
      {columns?.map(({ field }) => {
        return field === "name" ? (
          <TableCell key={field} component="th" scope="row" className={style.tableCell}>
            <ButtonComponent
              onClick={() => handleRedirect(row.id)}
              variant={BUTTON.VARIANT_TEXT}
              text={row[field]}
              size={BUTTON.SIZE__FIT_CONTENT}
            />
          </TableCell>
        ) : field === "title_translations" ? (
          <TableCell key={field} component="th" scope="row" className={style.tableCell}>
            <Typography idText={row[field].es} variant={"p"} />
          </TableCell>
        ) : (
          <TableCell key={field} component="th" scope="row" className={style.tableCell}>
            <Typography idText={row[field] || " - "} variant={"p"} />
          </TableCell>
        );
      })}
      <TableCell key={"field"} component="th" scope="row" className={style.tableCell}>
        <div className={style.actionCell}>
          <PlenaryMembersModalComponent plenaryMemberId={row?.id} onReloadData={onReloadData} />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default RowComponent;
