import React from "react";
import FilterPlenaryMembersComponent from "./components/filterPlenaryMembersComponent/FilterPlenaryMembersComponent";
import styles from "./PlenaryMembers.module.css";

const PlenaryMembersComponent = () => {
    return (
        <div>
            <FilterPlenaryMembersComponent styles={styles} />
        </div>
    );
};

export default PlenaryMembersComponent;
