import React from "react";

const SETTINGS_ICON_WHITE = (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 15.75C13.898 15.75 15.75 13.898 15.75 12C15.75 10.102 13.898 8.25 12 8.25C10.102 8.25 8.25 10.102 8.25 12C8.25 13.898 10.102 15.75 12 15.75Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M19.4 12.98C19.62 12 19.62 12 19.4 11.02L21 9.38C21.34 9.06 21.46 8.58 21.3 8.15L19.78 4.25C19.61 3.83 19.22 3.55 18.78 3.55H15.76C14.86 3.12 13.97 2.82 13 2.68V1C13 0.45 12.55 0 12 0C11.45 0 11 0.45 11 1V2.68C10.03 2.82 9.14 3.12 8.24 3.55H5.22C4.78 3.55 4.39 3.83 4.22 4.25L2.7 8.15C2.54 8.58 2.66 9.06 3 9.38L4.6 11.02C4.38 12 4.38 12 4.6 12.98L3 14.62C2.66 14.94 2.54 15.42 2.7 15.85L4.22 19.75C4.39 20.17 4.78 20.45 5.22 20.45H8.24C9.14 20.88 10.03 21.18 11 21.32V23C11 23.55 11.45 24 12 24C12.55 24 13 23.55 13 23V21.32C13.97 21.18 14.86 20.88 15.76 20.45H18.78C19.22 20.45 19.61 20.17 19.78 19.75L21.3 15.85C21.46 15.42 21.34 14.94 21 14.62L19.4 12.98Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SETTINGS_ICON_WHITE;
