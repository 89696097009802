import { APP_TENANT } from "./constants";

export const APP_BASE_ROUTE = `/${process.env.REACT_APP_BASE_ROUTE}/${APP_TENANT}`;

export const ROUTE_PLENARY_LIST = `/${APP_TENANT}`;
export const ROUTE_PLENARY_MEMBERS = `/${APP_TENANT}/miembros`;
export const ROUTE_PLENARY_MEMBERS_CREATE = `/${APP_TENANT}/miembros/crear`;
export const ROUTE_PLENARY_MEMBERS_EDIT = `/${APP_TENANT}/miembros/edit/:id`;
export const ROUTE_PLENARY_CREATION = `/${APP_TENANT}/crear`;
export const ROUTE_PLENARY_EDIT = `/${APP_TENANT}/edit/:id`;
export const ROUTE_PLENARY_DETAIL = `/${APP_TENANT}/:id`;
export const ROUTE_DESIGN = "/design";
