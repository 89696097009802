import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styles from "./PlenaryMembers.module.css";
import ButtonComponent from "../../shared/button/ButtonComponent";
import { useIntl } from "react-intl";
import { BUTTON } from "../../shared/button/buttonConstants";
import {
    COMMON__INT_CA,
    COMMON__INT_GL,
    COMMON__INT_ES,
    COMMON__INT_EN,
    COMMON__INT_EU,
    TITLE_MODAL_ERROR_MESSAGE_CERATION_MEMBER,
    ROOM_MODAL_ERROR_MESSAGE_CERATION_MEMBER,
    SEAT_MODAL_ERROR_MESSAGE_CERATION_MEMBER,
    TRANSLATION_MODAL_ERROR_MESSAGE_CERATION_MEMBER,
    COMMON__EDIT,
    MEMBER,
    NAME,
    MEMEBR_TITLE,
    ORGANIZATION,
    EMAIL,
    MEMBER_ROOM,
    SEAT,
    MICROPHONE,
    MEMEBR_TITLE_LAN,
    COMMON__NEW,
    NO_SEAT_AVAILABLE,
} from "../../../translations/constans";
import { ROLES_ALL } from "../../../utils/constants";
import { ROUTE_PLENARY_MEMBERS } from "../../../utils/routes";
import { useHistory } from "react-router-dom";
import { Card, CardContent, Tab, TextField } from "@mui/material";
import PageTitleComponent from "../../shared/pageTitle/PageTitleComponent";
import TypographyComponent from "../../shared/typography/TypographyComponent";
import SelectComponent from "../../shared/select/SelectComponent";
import { SELECT_VARIANT_BORDER } from "../../shared/select/SelectConstants";
import { COMMON_CANCEL, COMMON_SAVE } from "../../../translations/constans";
import CHECK_ICON from "../../../resources/CheckIcon";
import CANCEL_ICON from "../../../resources/CancelIcon";
import { getRooms, getLanguagesService, addPlenaryMember, getPlenaryMember, editPlenaryMember, getTenants } from "../../../services/commonservices";
import withAuthorization from "../../common/auth/withAuthorization";
import { TabList, TabPanel, TabContext } from "@material-ui/lab";
import Notification from "../../common/Notification";
import { useParams } from 'react-router-dom';

const PlenaryMembersEditComponent = (createMember) => {
    const intl = useIntl();
    const history = useHistory();
    const [error, setError] = useState(null);
    const [formError, setFormError] = useState("");

    const [tenantId, setTenantId] = useState("");
    const [name, setName] = useState("");
    const [organization, setOrganization] = useState("");
    const [email, setEmail] = useState("");

    const [defaultLanguage, setDefault] = useState([]);
    const [languages, setLanguages] = useState([]);

    const [rooms, setRooms] = useState([]);
    const [roomNames, setRoomNames] = useState([]);
    const [allSeats, setAllSeats] = useState([]);
    const [roomSeats, setRoomSeats] = useState([]);
    const [selectedRoom, selectRoom] = useState(null);
    const [selectedRoomName, selectRoomName] = useState(null);
    const [selectedSeat, selectSeat] = useState(null);
    const [selectedMic, selectMic] = useState(null);

    const [tabIndex, setTabVal] = useState("");
    const [tabValues, setTabValues] = useState({});

    const [memberData, setMemberData] = useState({});
    createMember = createMember.createMember;

    let memberId = useParams()

    const handleFillMemberData = (response) => {
        setMemberData(response.data)
        setName(response.data.name)
        setEmail(response.data.email)
        setOrganization(response.data.organization)
        Object.entries(response.data.title_translations).map(([lan, value]) => {
            return handleTabValues(lan, value);
        })
    }

    useEffect(() => {
        if (!createMember) {
            getPlenaryMember(memberId.id, handleFillMemberData, handleError)
        }
    }, [memberId])

    const languageMap = {
        es: intl.formatMessage({ id: COMMON__INT_ES }),
        en: intl.formatMessage({ id: COMMON__INT_EN }),
        gl: intl.formatMessage({ id: COMMON__INT_GL }),
        ca: intl.formatMessage({ id: COMMON__INT_CA }),
        eu: intl.formatMessage({ id: COMMON__INT_EU }),
    };

    const renderOptions = () => {
        const obj = {};
        for (const lang of languages) {
            if (defaultLanguage === lang) {
                obj[lang] = Yup.string().required();
            } else {
                obj[lang] = Yup.string();
            }
        }
        return obj;
    };

    const validationSchema = Yup.object({
        name: Yup.string(),
        email: Yup.string().email(),
        title_translations: Yup.object().shape(renderOptions()).required(),
        organization: Yup.string(),
        plenary_room_id: Yup.string().required(),
        default_seat_id: Yup.string().required(),
    });

    const handleError = (error) => {
        setError(error);
        console.log(error);
    }

    const handleGoBack = () => {
        history.push(ROUTE_PLENARY_MEMBERS);
    };

    const handleCreateMember = () => {
        setFormError("");

        if (!name) {
            setFormError(intl.formatMessage({ id: TITLE_MODAL_ERROR_MESSAGE_CERATION_MEMBER }));
            return
        }

        if (!selectedRoomName) {
            setFormError(intl.formatMessage({ id: ROOM_MODAL_ERROR_MESSAGE_CERATION_MEMBER }));
            return
        }

        if (!selectedSeat) {
            setFormError(intl.formatMessage({ id: SEAT_MODAL_ERROR_MESSAGE_CERATION_MEMBER }));
            return
        }

        if (!tabValues[defaultLanguage]) {
            setFormError(intl.formatMessage({ id: TRANSLATION_MODAL_ERROR_MESSAGE_CERATION_MEMBER }));
            return
        }

        const title_translations = languages.reduce((acc, clave) => {
            if (tabValues[clave]) {
                acc[clave] = tabValues[clave];
            }
            return acc;
        }, {});

        const body = {
            name: name,
            email: email,
            title_translations: title_translations,
            organization: organization,
            tenant_id: tenantId,
            plenary_room_id: selectedRoom.id,
            default_seat_id: selectedSeat,
        }

        validationSchema
            .validate(body)
            .then((validData) => {
                if (createMember) {
                    addPlenaryMember(body, handleGoBack, handleError);
                } else {
                    editPlenaryMember(memberId.id, body, handleGoBack, handleError)
                }
            })
    };

    const handleRoom = (event) => {
        const selectedIndex = event.target.selectedIndex;
        if (selectedIndex >= 0 && selectedIndex < rooms.length) {
            selectRoomName(event.target.value)
            selectRoom(rooms[selectedIndex])
            setRoomSeats(allSeats[selectedIndex])
        }
    }

    const handleSeat = (event) => {
        selectSeat(roomSeats[event.target.selectedIndex].id)
        selectMic(roomSeats[event.target.selectedIndex].name)
    }

    const handleChange = (newValue) => {
        setTabVal(newValue);
    };

    const handleTabValues = (lang, value) => {
        setTabValues((prevValues) => ({
            ...prevValues,
            [lang]: value,
        }));
    };

    const handleGetRooms = (response) => {
        const seats = response.data.map((item) => (item.seats.sort((a, b) => a.id - b.id)));
        setAllSeats(seats)
        setRoomSeats(seats[0])

        setRoomNames(response.data)
        setRooms(response.data)
    }

    const handleGetTenantId = (response) => {
        setTenantId(response.data.id)
    }

    const handleGetLanguages = (response) => {
        setDefault(response.data.default_language);
        setLanguages(response.data.available_languages);
    }

    useEffect(() => {
        if (createMember) {
            selectRoom(rooms[0])
            selectRoomName(roomNames[0])
        } else if (memberData?.plenary_room_id && rooms?.length > 0) {
            const memberRoomIndex = rooms.findIndex((room) => room.id === memberData.plenary_room_id);
            selectRoom(rooms[memberRoomIndex])
            selectRoomName(rooms[memberRoomIndex].name)
            setRoomSeats(allSeats[memberRoomIndex])
        }
    }, [rooms, memberData])

    useEffect(() => {
        if (roomSeats.length > 0) {
            if (createMember) {
                selectSeat(roomSeats[0].id)
                selectMic(roomSeats[0].name)
            } else if (memberData.default_seat_id) {
                const memberSeatIndex = roomSeats.findIndex((seat) => seat.id === memberData.default_seat_id);
                selectSeat(roomSeats[memberSeatIndex].id)
                selectMic(roomSeats[memberSeatIndex].name)
            }
        } else {
            selectSeat(null)
            selectMic(null)
        }
    }, [roomSeats, memberData])

    useEffect(() => {
        if (languages.length > 0 && defaultLanguage) {
            const defaultLanguageIndex = languages.findIndex((lang) => lang === defaultLanguage);
            setTabVal(defaultLanguageIndex !== -1 ? defaultLanguageIndex.toString() : "");
        }

        const initialValues = {};
        languages.forEach((lang) => {
            initialValues[lang] = "";
        });
        setTabValues(initialValues);
    }, [languages, defaultLanguage]);

    useEffect(() => {
        getTenants(handleGetTenantId, handleError)
        getRooms(handleGetRooms, handleError);
        getLanguagesService(handleGetLanguages, handleError)
    }, []);

    return (
        <div className={styles.pageContainer}>
            <div className={styles.headerPageContainer}>
                <div className={styles.title}>
                    <PageTitleComponent
                        title={createMember ? intl.formatMessage({ id: COMMON__NEW }) + " " + intl.formatMessage({ id: MEMBER }) :
                            memberData !== null ? intl.formatMessage({ id: COMMON__EDIT }) + " " + memberData.name : ""
                        }
                    />
                </div>
            </div>
            <Card className={styles.Card}>
                <CardContent>
                    <div className={styles.filterGroupContainer}>
                        <div>
                            <TypographyComponent
                                text={intl.formatMessage({ id: NAME })}
                            />
                            <TextField
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                key={"nameField"}
                                variant="outlined"
                                sx={{ width: "100%" }}
                            />
                        </div>
                        <div>
                            <TypographyComponent
                                text={intl.formatMessage({ id: ORGANIZATION })}
                            />
                            <TextField
                                value={organization}
                                onChange={(e) => setOrganization(e.target.value)}
                                key={"organizationField"}
                                variant="outlined"
                                sx={{ width: "100%" }}
                            />
                        </div>
                        <div>
                            <TypographyComponent
                                text={intl.formatMessage({ id: EMAIL })}
                            />
                            <TextField
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                key={"emailField"}
                                variant="outlined"
                                sx={{ width: "100%" }}
                            />
                        </div>
                    </div>
                    <div className={styles.filterGroupContainer}>
                        <div>
                            <TypographyComponent
                                text={intl.formatMessage({ id: MEMBER_ROOM })}
                            />
                            <SelectComponent
                                data={roomNames}
                                defaultValue={selectedRoomName || ""}
                                fieldLabel={"name"}
                                variant={SELECT_VARIANT_BORDER}
                                handleOnchange={handleRoom}
                            />

                        </div>
                        <div>
                            {roomSeats?.length > 0 && (
                                <TypographyComponent
                                    text={intl.formatMessage({ id: SEAT })}
                                />
                            )}
                            {roomSeats?.length > 0 && (
                                <SelectComponent
                                    data={roomSeats}
                                    defaultValue={selectedSeat}
                                    fieldLabel={"id"}
                                    variant={SELECT_VARIANT_BORDER}
                                    handleOnchange={handleSeat}
                                />
                            )}
                            {!(roomSeats?.length > 0) && (
                                <TypographyComponent
                                    text={intl.formatMessage({ id: NO_SEAT_AVAILABLE })}
                                    className={`${styles.typographyMarginBottom} ${styles.centeredRedText}`}
                                />
                            )}
                        </div>
                        <div>
                            {roomSeats?.length > 0 && (
                                <TypographyComponent
                                    text={intl.formatMessage({ id: MICROPHONE })}
                                />
                            )}
                            {roomSeats?.length > 0 && (
                                <SelectComponent
                                    data={roomSeats}
                                    defaultValue={selectedMic}
                                    fieldLabel={"name"}
                                    variant={SELECT_VARIANT_BORDER}
                                    handleOnchange={handleSeat}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.filterGroupContainer}>
                        <div>
                            {languages?.length > 0 &&
                                <TabContext value={tabIndex}>
                                    <TabList centered onChange={(e, value) => handleChange(value)} aria-label="icon label tabs example">
                                        {languages.map((lan, index) => {
                                            return (<Tab key={index.toString()} value={index.toString()} label={
                                                <span>
                                                    {languageMap[lan]}
                                                    {(lan === defaultLanguage) && <span style={{ color: 'red' }}> *</span>}
                                                </span>
                                            } />);
                                        })}
                                    </TabList>
                                    <TabPanel value={tabIndex.toString()}>
                                        <TypographyComponent
                                            text={intl.formatMessage({ id: MEMEBR_TITLE_LAN }) + languageMap[languages[tabIndex]]}
                                            className={styles.typography}
                                        />
                                        <TextField
                                            sx={{ width: "100%" }}
                                            variant="outlined"
                                            value={tabValues[languages[tabIndex]]}
                                            onChange={(event) => handleTabValues(languages[tabIndex], event.target.value)}
                                        />
                                    </TabPanel>
                                </TabContext>}
                        </div>
                    </div>

                    <div className={styles.buttonContainer}>
                        <ButtonComponent
                            text={intl.formatMessage({ id: COMMON_CANCEL })}
                            variant={BUTTON.VARIANT_SECONDARY}
                            icon={CANCEL_ICON}
                            onClick={handleGoBack}
                        />
                        <ButtonComponent
                            text={intl.formatMessage({ id: COMMON_SAVE })}
                            variant={BUTTON.VARIANT_PRIMARY}
                            icon={CHECK_ICON}
                            onClick={handleCreateMember}
                        />
                    </div>
                </CardContent>
            </Card>
            <Notification message={formError} clearMessage={() => setFormError('')} />
        </div>
    );
};

export default withAuthorization(ROLES_ALL)(PlenaryMembersEditComponent);
