import { NAME, MEMEBR_TITLE, ORGANIZATION, SEAT, EMAIL } from "../../../translations/constans";

export const plenaryMembersColumns = (intl) => [
  {
    headerName: intl.formatMessage({ id: NAME }),
    field: "name",
  },
  {
    headerName: intl.formatMessage({ id: ORGANIZATION }),
    field: "organization",
  },
  {
    headerName: intl.formatMessage({ id: MEMEBR_TITLE }),
    field: "title",
  },
  {
    headerName: intl.formatMessage({ id: EMAIL }),
    field: "email",
  },
  {
    headerName: intl.formatMessage({ id: SEAT }),
    field: "default_seat_id",
  },
];
