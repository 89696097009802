import React, { useEffect, useState } from "react";
import { BUTTON } from "../../../../shared/button/buttonConstants";
import ButtonComponent from "../../../../shared/button/ButtonComponent.jsx";
import { ROUTE_PLENARY_LIST, ROUTE_PLENARY_MEMBERS_CREATE } from "../../../../../utils/routes.js";
import { COMMON__BACK, CREATE_MEMBER_BUTTON, MEMBER_LIST__PAGE_TITLE, MEMEBR_TITLE, NAME, ORGANIZATION, } from "../../../../../translations/constans.js";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import BACK_ICON from "../../../../../resources/BackIcon";
import withAuthorization from "../../../../common/auth/withAuthorization";
import {
    ROLE_TIIVII_SUPER_USER,
    ROLE_TIIVIIPLEN_ADMIN_USER,
    ROLES_ALL,
    SESSION_PLENARY__LIMIT
} from "../../../../../utils/constants";
import PageTitleComponent from "../../../../shared/pageTitle/PageTitleComponent";
import { Paper, TextField } from "@mui/material";
import MemberListTableComponent from "./MemberListTable/MemberListTableComponent.jsx";
import { getPlenaryMembers } from "../../../../../services/commonservices.js";
import { plenaryMembersColumns } from "../../plenaryMembersConstants.js";
import SelectComponent from "../../../../shared/select/SelectComponent";
import TypographyComponent from "../../../../shared/typography/TypographyComponent";
import { SELECT_VARIANT_BORDER } from "../../../../shared/select/SelectConstants";
import { hasRoles } from "../../../../../utils/keycloak";
import ADD_CIRCLE_ICON from "../../../../../resources/AddCircleIcon";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { PLENARY_LIST__FILTER_SELECT_STATUS_ALL } from "../../../../../translations/constans.js";

const useStyles = makeStyles(() => ({
    edit: {
        "& .MuiInputBase-input": {
            fontWeight: "normal",
        }
    },
}));

const FilterPlenaryMembersComponent = ({ styles }) => {
    const history = useHistory();
    const intl = useIntl();
    const [offset, setOffset] = useState(0);
    const classes = useStyles();

    const keycloak = useSelector((state) => state.common.keycloak);
    const [members, setMembers] = useState([]);
    const [error, setError] = useState(null);

    const [organizationList, setOrganizationList] = useState([]);
    const [organizationFilter, setOrganization] = useState(null);

    const [titleList, setTitleList] = useState([]);
    const [titleFilter, setTitle] = useState(null);

    const [inputValue, setInputValue] = useState("");

    const handleGoBack = () => {
        history.push(ROUTE_PLENARY_LIST);
    };

    const handleGoEdit = () => {
        history.push(ROUTE_PLENARY_MEMBERS_CREATE);
    };

    const handleOffset = (page) => {
        setOffset(page * SESSION_PLENARY__LIMIT);
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleOrganization = (event) => {
        setOrganization(event.target.value);
    };

    const handleTitle = (event) => {
        setTitle(event.target.value);
    };

    const normalize = (str) =>
        str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    const filteredMembers = members.filter((member) => {
        const matchesOrganization = organizationFilter ? member.organization === organizationFilter : true;
        const matchesTitle = titleFilter ? member.title_translations.es === titleFilter : true;
        const matchesName = normalize(member.name).includes(normalize(inputValue));

        return matchesName && (matchesTitle || titleFilter === intl.formatMessage({ id: PLENARY_LIST__FILTER_SELECT_STATUS_ALL })) &&
            (matchesOrganization || organizationFilter === intl.formatMessage({ id: PLENARY_LIST__FILTER_SELECT_STATUS_ALL }));
    });

    const paginationTotalResults = filteredMembers.length;
    const currentPageMembers = filteredMembers.slice(
        offset,
        offset + SESSION_PLENARY__LIMIT
    )

    const handleSuccess = (response) => {
        setMembers(response.data);

        const organizationList = [...new Map(response.data.map((miembro) => [miembro.organization, { organization: miembro.organization }])).values()];
        const titleList = [...new Map(response.data.map((miembro) => [miembro.title_translations.es, { title: miembro.title_translations.es }])).values()];

        organizationList.unshift({ organization: intl.formatMessage({ id: PLENARY_LIST__FILTER_SELECT_STATUS_ALL }) })
        titleList.unshift({ title: intl.formatMessage({ id: PLENARY_LIST__FILTER_SELECT_STATUS_ALL }) })

        setOrganizationList(organizationList);
        setTitleList(titleList);
    };

    const handleError = (error) => {
        setError(error);
    };

    useEffect(() => {
        getPlenaryMembers(handleSuccess, handleError);
    }, []);

    const handleReload = () => {
        setOffset(0)
        getPlenaryMembers(handleSuccess, handleError);
    }

    return (<>
        <div className={styles.pageContainer}>
            <div className={styles.headerPageContainer}>
                <div className={styles.title}>
                    <div className={styles.buttonBack}>
                        <ButtonComponent
                            text={intl.formatMessage({ id: COMMON__BACK })}
                            icon={BACK_ICON}
                            variant={BUTTON.VARIANT_TEXT}
                            onClick={handleGoBack}
                        />
                    </div>
                    <PageTitleComponent title={intl.formatMessage({ id: MEMBER_LIST__PAGE_TITLE })} />
                </div>
                <div className={styles.buttonContainer}>
                    {hasRoles(keycloak, [ROLE_TIIVII_SUPER_USER, ROLE_TIIVIIPLEN_ADMIN_USER]) && (
                        <ButtonComponent
                            text={intl.formatMessage({ id: CREATE_MEMBER_BUTTON })}
                            variant={BUTTON.VARIANT_PRIMARY}
                            size={BUTTON.SIZE__FIT_CONTENT}
                            icon={ADD_CIRCLE_ICON}
                            onClick={handleGoEdit}
                        />
                    )}
                </div>
            </div>

            <Paper className={styles.table}>
                <div className={styles.filterGroupContainer}>
                    <div>
                        <TypographyComponent
                            text={intl.formatMessage({ id: NAME })}
                            variant={"p"}
                        />
                        <div>
                            <TextField
                                key={"nameSearcher"}
                                variant="outlined"
                                value={inputValue}
                                sx={{ width: "100%" }}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div>
                        <TypographyComponent
                            text={intl.formatMessage({ id: ORGANIZATION })}
                            variant={"p"}
                        />
                        <div>
                            <SelectComponent
                                data={organizationList}
                                defaultValue={organizationFilter}
                                fieldLabel={"organization"}
                                variant={SELECT_VARIANT_BORDER}
                                handleOnchange={handleOrganization}
                            />
                        </div>
                    </div>
                    <div>
                        <TypographyComponent
                            text={intl.formatMessage({ id: MEMEBR_TITLE })}
                        />
                        <div>
                            <SelectComponent
                                data={titleList}
                                defaultValue={titleFilter}
                                fieldLabel={"title"}
                                variant={SELECT_VARIANT_BORDER}
                                handleOnchange={handleTitle}
                            />
                        </div>
                    </div>
                </div>
                <MemberListTableComponent
                    columns={plenaryMembersColumns(intl)}
                    rows={currentPageMembers}
                    called={true}
                    onReloadData={handleReload}
                    handleOffset={handleOffset}
                    paginationTotalResults={paginationTotalResults}
                    limit={SESSION_PLENARY__LIMIT}
                />
            </Paper>
        </div>
    </>)
};

export default withAuthorization(ROLES_ALL)(FilterPlenaryMembersComponent);
